import { getContrast } from 'color2k';

const DARK_COLOR_THRESHOLD = 4.5;
const BLACKISH_COLOR_THRESHOLD = 1.5;

const isDarkColor = (color: string) => getContrast(color, '#fff') > DARK_COLOR_THRESHOLD;

const isBlackishColor = (color: string) => getContrast(color, '#000') <= BLACKISH_COLOR_THRESHOLD;

export {
  isBlackishColor,
  isDarkColor,
};
